import React, { forwardRef } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import HtmlParser from "../../components/HtmlParser"

export const CollapseParagraph = forwardRef(
  ({ node, index, isOpenned, clickHandler }, ref) => {
    const onClickHandler = index => {
      clickHandler(index)
    }

    const onKeyDownHandler = (event, index) => {
      if (event.key === "Enter") {
        onClickHandler(index)
      }
    }

    return (
      <div
        ref={ref}
        className={`paragraph paragraph-collapse border-b-2 border-gray-200${
          !isOpenned ? ` collapsed` : ` opened`
        }`}
      >
        <div
          className="collapse-title flex items-center py-4 cursor-pointer"
          tabIndex={0}
          onClick={() => onClickHandler(index)}
          onKeyDown={event => onKeyDownHandler(event, index)}
          role="button"
        >
          <div className="h3 flex-1 text-primary">{node.title}</div>
          <div className="icon flex-initial">
            <i
              className={`p-2 rounded-full border border-primary fas fa-${
                !isOpenned
                  ? `plus bg-white text-primary`
                  : `minus bg-primary text-white`
              }`}
            >
              <span className="hidden">Déplier</span>
            </i>
          </div>
        </div>
        <div
          className={`collapse-content p-4 lg:py-8 bg-primary-light bg-opacity-10 border-t-2 border-gray-200${
            !isOpenned ? ` hidden` : ` block`
          }`}
        >
          <div className="content">
            <HtmlParser html={node.text.value} />
          </div>
        </div>
      </div>
    )
  }
)

CollapseParagraph.propTypes = {
  node: PropTypes.node.isRequired,
  index: PropTypes.number,
  isOpenned: PropTypes.bool,
  clickHandler: PropTypes.func,
}

CollapseParagraph.defaultProps = {
  node: null,
  index: null,
  isOpenned: true,
  clickHandler: null,
}

export const fragment = graphql`
  fragment CollapseParagraph on paragraph__collapse {
    id
    title: field_title
    text: field_text {
      value
    }
  }
`
