import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

export const CardParagraph = ({ node }) => (
  <div className="paragraph paragraph-card relative">
    {node.relationships.image && (
      <div className="card-image relative">
        <Img
          fluid={node.relationships.image.localFile.childImageSharp.fluid}
          alt={node.title}
        />
      </div>
    )}
    <div className={`card-content py-4 px-2`}>
      <div className="h-full flex flex-col">
        <div className="text-lg text-secondary font-semibold mb-4">
          {node.title}
        </div>

        <div
          className={`content text-sm text-secondary ${
            node.link ? ` mb-4` : ``
          }`}
          dangerouslySetInnerHTML={{ __html: node.text.value }}
        />

        {node.link && (
          <div className="link">
            <a
              href={node.link.uri}
              className="button button-secondary"
              target="_blank"
              rel="noreferrer"
            >
              En savoir plus
            </a>
          </div>
        )}
      </div>
    </div>
  </div>
)

export const fragment = graphql`
  fragment CardParagraph on paragraph__card {
    id
    image: field_image {
      alt
    }
    title: field_title
    text: field_text {
      value
    }
    link: field_link {
      title
      uri
    }
    relationships {
      image: field_image {
        localFile {
          childImageSharp {
            fluid(
              maxWidth: 332
              maxHeight: 208
              cropFocus: CENTER
              quality: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
