import React from "react"
import { graphql } from "gatsby"
import HtmlParser from "../../components/HtmlParser"

export const HighlightedParagraph = ({ node }) => (
  <div className="paragraph paragraph-highlighted">
    <div className="relative">
      <div className="absolute inset-0 bg-white border border-primary transform -translate-x-4 -translate-y-4 lg:-translate-x-8 lg:-translate-y-8">
        &nbsp;
      </div>
      <div className="relative p-8 bg-primary text-white">
        <div className="content">
          <HtmlParser html={node.text.value} />
        </div>
      </div>
    </div>
  </div>
)

export const fragment = graphql`
  fragment HighlightedParagraph on paragraph__highlighted {
    id
    text: field_text {
      value
    }
  }
`
