import React from "react"
import { useWebformElement, WebformElementWrapper } from "gatsby-drupal-webform"

const WebformFieldSelect = ({ element, error }) => {
  const [inputProps, settings] = useWebformElement(element, {
    name: element.name,
    type: "select",
  })

  // Empty options
  let emptyLabel = null
  let emptyValue = null

  element?.attributes.map(({ name, value }, index) => {
    switch (name) {
      case "#empty_option":
        emptyLabel = value
        break
      case "#empty_value":
        emptyValue = value
        break
      default:
        break
    }

    return null
  })

  return (
    <>
      {element.options && element.options.length > 0 && (
        <WebformElementWrapper
          className="field md:col-span-2"
          settings={settings}
          error={error}
          labelFor={element.name}
          labelClassName={"label"}
        >
          <div className="control relative">
            <div className="icon absolute right-0 inset-y-0 py-2 px-4 text-secondary">
              <div className="fas fa-caret-down"></div>
            </div>
            <select
              name={element.name}
              id={element.name}
              className="select appearance-none"
              {...inputProps}
            >
              {emptyLabel && (
                <option value={emptyValue} className="option" selected>
                  {emptyLabel}
                </option>
              )}
              {element.options.map(({ label, value }, index) => (
                <option key={index} value={value} className="option">
                  {label}
                </option>
              ))}
            </select>
          </div>
        </WebformElementWrapper>
      )}
    </>
  )
}

export default WebformFieldSelect
