import React, { useState, useEffect, createRef, useRef } from "react"
import { graphql } from "gatsby"
import { CollapseParagraph } from "./Collapse"
import { isMobile } from "../../Utils/functions"

export const AccordionParagraph = ({ node }) => {
  const [opened, setOpened] = useState(0)

  const toggle = index => {
    setOpened(index === opened ? null : index)
  }

  const didMount = useRef(false)

  const scrollToRef = (ref, offset) => {
    window.scrollTo(0, ref.current.offsetTop - offset)
  }

  useEffect(() => {
    if (didMount.current) {
      if (null !== opened && isMobile()) {
        setTimeout(() => {
          scrollToRef(node.relationships.collapses[opened].ref, 104)
        }, 300)
      }
    } else {
      didMount.current = true
    }
  }, [opened, node.relationships.collapses])

  return (
    <div className="paragraph paragraph-accordion">
      {node.title && (
        <h2 className="title h2 text-primary">
          <span>{node.title}</span>
        </h2>
      )}

      {node.relationships.collapses.map((collapse, index) => {
        collapse.ref = createRef()

        return (
          <CollapseParagraph
            key={index}
            node={collapse}
            index={index}
            isOpenned={index === opened}
            clickHandler={toggle}
            ref={collapse.ref}
          />
        )
      })}
    </div>
  )
}

export const fragment = graphql`
  fragment AccordionParagraph on paragraph__accordion {
    id
    title: field_title
    relationships {
      collapses: field_paragraphs {
        type: __typename
        ... on Node {
          ...CollapseParagraph
        }
      }
    }
  }
`
