import React, { useState } from "react"
import { useWebformElement, WebformElementWrapper } from "gatsby-drupal-webform"

const WebformFieldCheckboxes = ({ element, error }) => {
  const [checked, setChecked] = useState(null)

  const [inputProps, settings] = useWebformElement(element, {
    name: element.name,
    type: "checkbox",
  })

  return (
    <>
      {element.options && element.options.length > 0 && (
        <WebformElementWrapper
          className="field md:col-span-2"
          settings={settings}
          error={error}
          labelFor={element.name}
          labelClassName="label"
        >
          <div className="checkboxes flex flex-wrap flex-col md:flex-row -mx-4">
            {element.options.map(({ label, value }, index) => {
              let elementId = `${element.name}_${value}`
              let isChecked = checked === elementId

              return (
                <div key={index} className="control px-4">
                  <input
                    id={elementId}
                    name={element.name}
                    value={value}
                    className="checkbox hidden"
                    onChange={() =>
                      setChecked(checked === elementId ? null : elementId)
                    }
                    {...inputProps}
                  />

                  <span
                    className={`checkbox-icon${isChecked ? ` checked` : ``}`}
                  >
                    <i
                      className={
                        isChecked ? `far fa-check-square` : `far fa-square`
                      }
                    ></i>
                  </span>

                  <label htmlFor={elementId} className="label-inline px-2">
                    {label}
                  </label>
                </div>
              )
            })}
          </div>
        </WebformElementWrapper>
      )}
    </>
  )
}

export default WebformFieldCheckboxes
