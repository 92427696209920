import React from "react"
import { graphql } from "gatsby"
import { getIconFromExtension } from "../../Utils/functions"

export const FilesMultiuploadParagraph = ({ node }) => {
  return (
    <div
      className={`paragraph paragraph-files-multiupload p-8 lg:py-12 lg:px-24 bg-primary`}
    >
      {node.title && (
        <h2 className={"title h2 text-white"}>
          <span>{node.title}</span>
        </h2>
      )}
      <ul
        className={`files grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12`}
      >
        {node.relationships.files.map((file, index) => {
          let fileData = node.files[index]
          let fileName =
            fileData && fileData.description
              ? fileData.description
              : file.localFile.base

          return (
            <div key={index} className="file">
              <div className="paragraph paragraph-file-multiupload h-full">
                <a
                  href={file.localFile.publicURL}
                  title={`Télécharger "${file.localFile.base}" (${file.localFile.prettySize})`}
                  className="flex items-center h-full text-lg file text-white hover:text-secondary"
                  download
                >
                  <div className="icon mr-4 text-2xl">
                    <i
                      className={`fas fa-${getIconFromExtension(
                        file.localFile.extension
                      )}`}
                    ></i>
                  </div>
                  <span className="icon-explain">{fileName}</span>
                </a>
              </div>
            </div>
          )
        })}
      </ul>
    </div>
  )
}

export const fragment = graphql`
  fragment FilesMultiuploadParagraph on paragraph__files_multiupload {
    id
    title: field_title
    files: field_files {
      description
    }
    relationships {
      files: field_files {
        localFile {
          publicURL
          prettySize
          extension
          base
        }
      }
    }
  }
`
