import React, { createRef, useState, useEffect } from "react"
import Webform from "gatsby-drupal-webform"
import WebformFieldCheckbox from "./Webform/WebformFieldCheckbox"
import WebformFieldCheckboxes from "./Webform/WebformFieldCheckboxes"
import WebformFieldEmail from "./Webform/WebformFieldEmail"
import WebformFieldText from "./Webform/WebformFieldText"
import WebformFieldTextarea from "./Webform/WebformFieldTextarea"
import WebformFieldPhone from "./Webform/WebformFieldPhone"
import WebformFieldSelect from "./Webform/WebformFieldSelect"
import WebformFieldRadios from "./Webform/WebformFieldRadios"
import HtmlParser from "./HtmlParser"
import { classNames } from "../Utils/functions"

const Form = ({ webform, ...props }) => {
  const [status, setStatus] = useState(null)
  const [message, setMessage] = useState("")
  const [formKey, setFormKey] = useState(new Date().getTime())
  const messageRef = createRef()
  const webformId = "webform-" + Math.floor(Math.random() * Date.now())

  const successHandler = response => {
    if (response.settings) {
      setStatus("success")
      setMessage(response.settings.confirmation_message)

      document.getElementById(webformId).reset()
    }
  }

  const validateHandler = event => {
    setStatus("loading")
    event.preventDefault()

    let isValid = event.currentTarget.checkValidity()

    if (!isValid) {
      setStatus("error")
      setMessage(
        "Au moins un champ de ce formulaire n'est pas conforme. Merci de contrôler votre saisie."
      )
    }

    return isValid
  }

  useEffect(() => {
    if (status === "success") {
      setFormKey(new Date().getTime())
    }
  }, [status])

  useEffect(() => {
    if (message !== "" && messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }
  }, [message])

  return (
    <>
      <Webform
        id={webformId}
        key={formKey}
        webform={webform}
        customComponents={{
          textfield: WebformFieldText,
          email: WebformFieldEmail,
          tel: WebformFieldPhone,
          checkbox: WebformFieldCheckbox,
          checkboxes: WebformFieldCheckboxes,
          select: WebformFieldSelect,
          textarea: WebformFieldTextarea,
          radios: WebformFieldRadios,
        }}
        endpoint={`${process.env.API_URL}${process.env.WEBFORM_ENDPOINT}`}
        onSuccess={successHandler}
        onValidate={validateHandler}
        {...props}
      />
      {message !== "" && (
        <div
          ref={messageRef}
          className={classNames(
            "mt-8 p-4 bg-opacity-75 text-white",
            status === "success" && "bg-green-400",
            status === "loading" && "bg-yellow-500",
            status === "error" && "bg-red-400"
          )}
        >
          <HtmlParser html={message} />
        </div>
      )}
    </>
  )
}

export default Form
