import React from "react"
import { useWebformElement, WebformElementWrapper } from "gatsby-drupal-webform"

const WebformFieldCheckbox = ({ element, error }) => {
  const [inputProps, settings] = useWebformElement(element, {
    name: element.name,
    type: "checkbox",
  })

  return (
    <WebformElementWrapper
      className="field md:col-span-2 flex items-baseline gap-2"
      settings={settings}
      error={error}
      labelFor={element.name}
      labelClassName="label-inline flex-1 order-2"
    >
      <div className="control-inline flex-initial order-1">
        <input id={element.name} className="checkbox" {...inputProps} />
      </div>
    </WebformElementWrapper>
  )
}

export default WebformFieldCheckbox
