import React from "react"
import { graphql } from "gatsby"
import { getParagraph } from "../helpers/paragraph-helpers"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import PageHeader from "../components/PageHeader"
import Form from "../components/Form"
import HtmlParser from "../components/HtmlParser"
import MenuLink from "../components/MenuLink"

const Page = ({ data }) => {
  const paragraphs = data.node.relationships.paragraphs.map(getParagraph)

  return (
    <Layout contentType={data.node.internal.type}>
      <Metas title={data.node.title} />
      <PageHeader title={data.node.title} />
      <section className="section page-content">
        <div className="container mx-auto px-4">
          <div className={`grid grid-cols-1 gap-8`}>
            {data.node.body && (
              <div
                className="block content mb-8 lg:px-24"
                data-typesense-field="body"
              >
                <HtmlParser html={data.node.body.value} />
              </div>
            )}

            {paragraphs.length > 0 && (
              <div className="paragraphs-list">{paragraphs}</div>
            )}

            {data.node.relationships.webform && (
              <section className="page-webform lg:px-24">
                <Form webform={data.node.relationships.webform} />
              </section>
            )}

            {data.children && data.children.nodes.length > 0 && (
              <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                {data.children.nodes.map((item, index) => (
                  <div key={index} className="column portal-link h-full">
                    <MenuLink
                      internalId={item.link.uri}
                      className="group block relative px-4 pt-4 pb-12 bg-secondary text-white md:h-32 hover:text-white"
                    >
                      <span className="text-lg font-semibold">
                        {item.title}
                      </span>
                      <div className="icon absolute bottom-0 right-0 px-4 py-2 text-lg bg-primary text-white transform translate-x-1/4 translate-y-1/4">
                        <i className="fas fa-chevron-right">
                          <span className="hidden">En lire plus</span>
                        </i>
                      </div>
                    </MenuLink>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($internalId: Int!, $menuItemId: String) {
    node: nodePage(
      status: { eq: true }
      drupal_internal__nid: { eq: $internalId }
    ) {
      title
      body {
        summary
        value
      }
      changed
      relationships {
        paragraphs: field_paragraphes {
          type: __typename
          ... on Node {
            ...TextParagraph
            ...BannerParagraph
            ...HighlightedParagraph
            ...ListParagraph
            ...FilesMultiuploadParagraph
            ...AccordionParagraph
            ...CollapseParagraph
            ...ImageParagraph
            ...ImageGalleryParagraph
          }
        }
        webform: field_webform {
          title
          drupal_internal__id
          elements {
            name
            type
            attributes {
              name
              value
            }
            options {
              label
              value
            }
          }
        }
      }
      internal {
        type
      }
    }
    children: allMenuLinkContentMenuLinkContent(
      filter: {
        enabled: { eq: true }
        menu_name: { eq: "main" }
        drupal_parent_menu_item: { eq: $menuItemId, ne: null }
      }
      sort: { fields: weight, order: ASC }
    ) {
      nodes {
        title
        drupal_parent_menu_item
        drupal_id
        link {
          uri
          options {
            attributes {
              data_has_icon
              data_icon
              data_text_invisible
              target
            }
          }
        }
      }
    }
  }
`
